@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&family=Reddit+Sans:ital,wght@0,200..900;1,200..900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.urbanist-400 {
    font-family: 'Urbanist', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.urbanist-500 {
    font-family: 'Urbanist', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.reddit-sans-400 {
    font-family: "Reddit Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.container {
    position: relative;
    width: 100%;
    height: 100%;
  }

.text {
    word-break: break-word;
    position: absolute;
    top: 0;
    left: 0;
}

.headline {
    top: 35%;
    left: 6%;
    font-size: 105pt;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
}

canvas {
    height: -webkit-fill-available;
    opacity: 0;
    animation: fadeIn 1.5s forwards;
}